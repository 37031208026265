"use strict";

// BURGER MENU
const burgerMenu = document.querySelector(".nav__links"),
burgerMenuBtn = document.querySelector(".burger__menu");

let burgerMenuSvg = burgerMenuBtn.querySelectorAll("svg");

burgerMenuBtn.addEventListener("click", function(){
  window.scrollTo({ top: 0, behavior: 'smooth' });
  burgerMenu.classList.toggle("nav__links--hidden");
  for (const svg of burgerMenuSvg) {
      svg.classList.toggle('burger__menu--hidden');
  }
  burgerMenuBtn.classList.toggle("burger__menu--fixed");
});

// DATE FOOTER
var copyright = document.querySelector(".current-year");
copyright.innerText = new Date().getFullYear();